import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { withFormik, FormikProps, FormikErrors, Form, Field } from 'formik';

import { addSignIn, getSignIn } from './BE';

import { Test } from './test.model';

import { TransactionView } from './TransactionView';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

interface SignInFormValues {
  allowCookies: boolean;
  acceptDataHandling: boolean;
  lastname: string;
  prename: string;
  dob: number;
  phone: string;
  email: string;
  street: string;
  zip: string;
  city: string;
}

interface OtherProps {
}

const InnerForm = (props: OtherProps & FormikProps<SignInFormValues>) => {
  const { touched, errors, isSubmitting } = props;
  return (
    <Form>
      <div className='checkbox-wrapper'>
        <Field type="checkbox" className="checkbox" name="allowCookies" />
        <label htmlFor="allowCookies" className='checkbox-label'>
          Cookies zur Abwicklung erlauben
          <i className="icon-info bi bi-info-circle-fill infobox" >
            <div className="infoboxtext">
              <p>Es kann nur mit Ihrem Gerät und dem für die Anmeldung eingesetzten Browser auf die Daten dieser Anmeldung zugegriffen werden. Dazu müssen Informationen auf Ihrem Handy gespeichert werden.</p>
              <p>Damit das funktioniert, muss Ihr Browser so eingestellt sein, dass diese Informationen verfügbar sind, wenn Sie das Ergebnis abrufen:</p>
              <ul>
                <li>Es müssen Cookies erlaubt sein</li>
                <li>Die Seite muss im Standardmodus aufgerufen werden (KEIN Inkognito-/Private-Modus)</li>
              </ul>
              <p>(Die üblichen Browser sind standardmäßig so eingestellt, d.h. Sie brauchen in der Regel nichts zu tun.)</p>
            </div>
          </i>
        </label>
      </div>
      {touched.allowCookies && errors.allowCookies ? <div className="input-error-message">{errors.allowCookies}</div> : ''}
      <div className='checkbox-wrapper'>
        <Field type="checkbox" className="checkbox" name="acceptDataHandling" />
        <label htmlFor="acceptDataHandling" className='checkbox-label'>
          Ich bin mit der Verarbeitung meiner persönlichen Daten gemäß der gesetzlichen Bestimmungen einverstanden.
          <i className="icon-info bi bi-info-circle-fill infobox" >
            <div className="infoboxtext">
              <p>Zweck der Verarbeitung, Rechtsgrundlage, gesetzliche Verpflichtung zur Erhebung:</p>
              <p>Sicherstellung der effektiven Rückverfolgbarkeit von Infektionen; es besteht eine Verpflichtung zur Erhebung der Kontaktdaten nach Art. 6 Abs 1 Buchst. C DSGVO i.V.m §4 Abs. 2 der Landesverordnung zur Neuverfassung der Corona-Bekämpfungsverordnung.</p>
              <p>Empfänger der erhobenen Kontaktdaten:</p>
              <p>Die Kontaktdaten sind auf Verlangen an die zuständigen Behörden (Gesundheitsamt) zu übermitteln, sofern die zur Nachverfolgung von möglichen Infektion erforderlich ist. Die Kontaktdaten werden für einen Zeitraum von vier Wochen aufbewahrt und anschließend vernichtet.
               <p>Ihre Rechte:</p>
                <p>Sie haben als betroffene Person in Bezug auf die erhobenen Kontaktdaten des Recht auf Auskunft, nach Ablauf der Aufbewahrungsfrist gegenüber dem Verantwortlichen das Recht auf Löschung. Nutzen Sie zur Wahrnehmung Ihrer Rechte die Kontaktdaten des Verantwortlichen. Es besteht eine Beschwerderecht bei einer Datenschutzaufsichtsbehörde (Landesbeauftragte für Datenschutz, Holstenstrasse 98, 24103 Kiel, www.datenschutzzentrum.de</p>
              </p>
              <p>Kontaktdaten des Verantwortlichen:</p>
              <p>Karl- Ernst Hartkopf Flensburger Strasse 7 / 25142 Pinneberg, Tel : +49 1525 85 19 749</p>
            </div>
          </i>
        </label>
      </div>
      {touched.acceptDataHandling && errors.acceptDataHandling ? <div className="input-error-message">{errors.acceptDataHandling}</div> : ''}
      <div className="input-wrapper">
        <Field type="text" name="lastname" className="form-control" placeholder="Nachname" />
        <label htmlFor="last" className="control-label">Nachname</label>
      </div>
      {touched.lastname && errors.lastname && <div className="input-error-message">{errors.lastname}</div>}
      <div className="input-wrapper">
        <Field type="text" name="prename" className="form-control" placeholder="Vorname" />
        <label htmlFor="prename" className="control-label">Vorname</label>
        {touched.prename && errors.prename && <div className="input-error-message">{errors.prename}</div>}
      </div>
      {/* <div className="input-wrapper">
        <Field type="number" name="dob" className="form-control" placeholder="Geburtsdatum" />
        <label htmlFor="dob" className="control-label">Geburtsdatum</label>
        {touched.dob && errors.dob && <div className="input-error-message">{errors.dob}</div>}
      </div> */}
      <div className="input-wrapper">
        <Field type="tel" name="phone" className="form-control" placeholder="Telefon-Nr" />
        <label htmlFor="phone" className="control-label">Telefon-Nr</label>
        {touched.phone && errors.phone && <div className="input-error-message">{errors.phone}</div>}
      </div>
      <div className="input-wrapper">
        <Field type="text" name="street" className="form-control" placeholder="Straße" />
        <label htmlFor="street" className="control-label">Straße</label>
        {touched.street && errors.street && <div className="input-error-message">{errors.street}</div>}
      </div>
      <div className="input-wrapper">
        <Field type="text" name="zip" className="form-control" placeholder="Postleitzahl" />
        <label htmlFor="zip" className="control-label">Postleitzahl</label>
        {touched.zip && errors.zip && <div className="input-error-message">{errors.zip}</div>}
      </div>
      <div className="input-wrapper">
        <Field type="text" name="city" className="form-control" placeholder="Stadt" />
        <label htmlFor="Stadt" className="control-label">Stadt</label>
        {touched.city && errors.city && <div className="input-error-message">{errors.city}</div>}
      </div>
      <div className="input-wrapper">
        <Field type="email" name="email" className="form-control" placeholder="eMail-Adresse" />
        <label htmlFor="email" className="control-label">eMail-Adresse</label>
        {touched.email && errors.email && <div className="input-error-message">{errors.email}</div>}
      </div>
      <div className='button-bar'>
        <button type="submit" className="btn btn-primary button-solitaire" disabled={isSubmitting}>
          anmelden
       </button>
      </div>
    </Form>
  );
};

// The type of props SignInForm receives
interface SignInFormProps {
  initialLastname?: string;
  initialPrename?: string;
  initialDob?: number;
  initialPhone?: string;
  initialEmail?: string;
  initialStreet?: string;
  initialZip?: string;
  initialCity?: string;
  setFormMessage: React.Dispatch<React.SetStateAction<string>>
  setTest: React.Dispatch<React.SetStateAction<Test>>
}

// Wrap our form with the withFormik HoC
const SignInForm = withFormik<SignInFormProps, SignInFormValues>({
  // Transform outer props into form values
  mapPropsToValues: props => {
    return {
      allowCookies: false,
      acceptDataHandling: false,
      lastname: props.initialLastname || '',
      prename: props.initialPrename || '',
      dob: props.initialDob || 0,
      phone: props.initialPhone || '',
      email: props.initialEmail || '',
      street: props.initialStreet || '',
      zip: props.initialZip || '',
      city: props.initialZip || '',
    };
  },

  // Add a custom validation function (this can be async too!)
  validate: (values: SignInFormValues, props: SignInFormProps) => {

    props.setFormMessage('');
    let errors: FormikErrors<SignInFormValues> = {};

    if (!values.allowCookies) {
      errors.allowCookies = 'Zur Abwicklung des Schnelltests müssen Cookies verwendet werden';
    }
    if (values.allowCookies) {
      try {
        localStorage.setItem('tmt', 'test');
        localStorage.removeItem('tmt');
      } catch (error) {
        errors.allowCookies = 'Es konnen keine Cookies gespeichert werden. Ändern Sie die Einstallung Ihres Browsers entsprechend oder benutzen Sie einen geeigneten Browser';
      }
    }
    if (!values.acceptDataHandling) {
      errors.acceptDataHandling = 'Zur Abwicklung ist Ihre Zustimmung notwendig';
    }
    if (!values.lastname) {
      errors.lastname = 'Der Name wird benötigt';
    }
    if (!values.phone) {
      errors.phone = 'Die Telefon-Nr wird benötigt';
    }
    if (!values.email) {
      errors.email = 'Die eMail-Adresse wird benötigt';
    } else {
      if (!Yup.string().email(values.email)) {
        errors.email = 'eMail-Adresse hat ungültiges Format';
      }
    }
    if (!values.street) {
      errors.street = 'Die Straße wird benötigt';
    }
    if (!values.zip) {
      errors.zip = 'Die Postleitzahl wird benötigt';
    }
    if (!values.city) {
      errors.city = 'Die Stadt wird benötigt';
    }

    // if (!values.phone && !values.email){
    //   errors.phone = 'Telefon-Nummer oder eMail-Adresse wird benötigt.';
    //   errors.email = 'Telefon-Nummer oder eMail-Adresse wird benötigt.';
    // }

    return errors;
  },

  handleSubmit: (values, formikBag) => {

    addSignIn(
      {
        sign_in_id: Date.now(),
        ...(values.lastname ? { lastname: values.lastname } : {}),
        ...(values.prename ? { prename: values.prename } : {}),
        ...(values.dob ? { dob: values.dob } : {}),
        ...(values.phone ? { phone: values.phone } : {}),
        ...(values.email ? { email: values.email } : {}),
        ...(values.street ? { street: values.street } : {}),
        ...(values.zip ? { zip: values.zip } : {}),
        ...(values.city ? { city: values.city } : {}),
      }
    )
      .then(test => {
        try {
          localStorage.setItem('tmt', JSON.stringify(test));
          formikBag.props.setTest(test);
        } catch (error) {
          formikBag.props.setFormMessage('Es konnen keine Cookies gespeichert werden. Ändern Sie die Einstallung Ihres Browsers entsprechend oder benutzen Sie einen geeigneten Browser');
        }
      })
      .catch(err => {
        formikBag.props.setFormMessage(`Der Test-Vorgang kann nicht angelegt werden. Versuchen Sie es bitte etwas später noch einmal.`)
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });

  },

})(InnerForm);

const App = () => {

  const [touched, setTouched] = useState(false);
  const [onLine, setOnLine] = useState(true);
  const [cookiesAllowed, setCookiesAllowed] = useState(true);
  const [test, setTest] = useState<Test>({ sign_in_id: 1 });

  const refreshTest = (sign_in_id: number) => {
    getSignIn(sign_in_id)
      .then(test => setTest(test))
      .catch(error => {
        console.log(`App - refreshTest - error: ${error}`)
        setTest({ sign_in_id: 0 });
      })
  }

  useEffect(() => {

    if (!touched) {
      if (!navigator.onLine) {
        setOnLine(false);
        return;
      }
      setOnLine(true);
      setTouched(true);
      let tmtTest: Test = { sign_in_id: 0 };
      try {
        const tmtValue = localStorage.getItem("tmt") || '';
        try {
          tmtTest = JSON.parse(tmtValue);
        } catch {
          tmtTest = { sign_in_id: 0 };
        }
      } catch (error) {
        setCookiesAllowed(false);
        return;
      }

      if (tmtTest.sign_in_id === 0) {
        localStorage.setItem("tmt", JSON.stringify((tmtTest)));
        setTest(tmtTest);
        return;
      }
      getSignIn(tmtTest.sign_in_id)
        .then(test => {
          if (!test) {
            localStorage.setItem("tmt", JSON.stringify({ sign_in_id: 0 }));
            setTest({ sign_in_id: 0 })
          } else {
            localStorage.setItem("tmt", JSON.stringify((tmtTest)));
            setTest(test)
          }
        })
        .catch(error => {
          setTest({ sign_in_id: 0 });
        })
    }

  });

  const [formMessage, setFormMessage] = useState('');


  // const onTerminateSignIn = (sign_in_id: number) => {
  //   localStorage.removeItem('tmt');
  //   window.location.reload();
  // }

  return (
    <div>
      { onLine ?
        cookiesAllowed ?
          <div>
            {test.sign_in_id === 0 ?
              <div>
                <nav className="navbar py-0 navbar-dark bg-primary navbar-expand-lg">
                  <div className='navbar-brand title'>Covid Center HH - Anmelden</div>
                  <button
                    className="navbar-toggler py-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTm1"
                    aria-controls="navbarTm1"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon py-0"></span>
                  </button>
                  <div className="collapse py-0 navbar-collapse" id="navbarTm1">
                    <ul className="navbar-nav py-0 mr-auto mt-2 mt-lg-0">
                      <li className="nav-item nav-link py-0 disalbed">Hilfe</li>
                    </ul>
                  </div>
                </nav>

                <div className='subtitle'><b>Zur Anmeldung für einen NEUEN Test</b> bitte Ihre persönlichen Daten eingeben</div>
                <div className='input-error-message'>{formMessage}</div>
                <SignInForm setFormMessage={setFormMessage} setTest={setTest} />
              </div>
              : <div>
                <TransactionView test={test} refreshTest={refreshTest} setFormMessage={setFormMessage} />
              </div>
            }
          </div>
          :
          <div>
            <nav className="navbar py-0 navbar-dark bg-primary navbar-expand-lg">
              <div className='navbar-brand title'>Covid Center HH - Anmelden</div>
              <button
                className="navbar-toggler py-0"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTm1"
                aria-controls="navbarTm1"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon py-0"></span>
              </button>
              <div className="collapse py-0 navbar-collapse" id="navbarTm1">
                <ul className="navbar-nav py-0 mr-auto mt-2 mt-lg-0">
                  {/* <li
                  className="nav-item nav-link py-1"
                  onClick={() => {
                    if (window.confirm('Soll der Vorgang wirklich abgeschlossen werden?'))
                      onTerminateSignIn(test.sign_in_id)
                  }}
                >Vorgang abschließen</li>
                <li className="nav-item nav-link py-0 disalbed">Hilfe</li> */}
                </ul>
              </div>
            </nav>
            <div className="viewport-error-message">Es kann nicht mit Cookies gearbeitet werden. Ändern Sie die Einstellung Ihres Browsers entsprechend.</div>
          </div>
        :
        <div>
          <nav className="navbar py-0 navbar-dark bg-primary navbar-expand-lg">
            <div className='navbar-brand title'>Covid Center HH - Anmelden</div>
            <button
              className="navbar-toggler py-0"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTm1"
              aria-controls="navbarTm1"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon py-0"></span>
            </button>
            <div className="collapse py-0 navbar-collapse" id="navbarTm1">
              <ul className="navbar-nav py-0 mr-auto mt-2 mt-lg-0">
                {/* <li
                  className="nav-item nav-link py-1"
                  onClick={() => {
                    if (window.confirm('Soll der Vorgang wirklich abgeschlossen werden?'))
                      onTerminateSignIn(test.sign_in_id)
                  }}
                >Vorgang abschließen</li>
                <li className="nav-item nav-link py-0 disalbed">Hilfe</li> */}
              </ul>
            </div>
          </nav>
          <div className="viewport-error-message">Sie haben zur Zeit keine Verbindung zum Internet. Versuchen Sie es bitte später noch einmal.</div>
        </div>
      }
    </div>
  )
};


export default App;