import axios from 'axios';
import { Test } from './test.model';

const BE_URL = process.env.REACT_APP_BE_URL

export const getSignIn = async (sign_in_id:number) => {

    return axios.get(BE_URL + `signin/${sign_in_id.toString()}`)
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const getSample = async (sample_id:number) => {

    return axios.get(BE_URL + `sample/${sample_id.toString()}`)
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const addSignIn = async (test:Test) => {

    return axios.post(BE_URL + 'addsignin', test )
    .then(res => {
        return res.data
    })
    .catch(error => {throw error})
    
}

export const assignSample2SignIn = async (sample_id:number, sign_in_id:number) => {

    return axios.get(BE_URL + `assignsample2signin`,{params:{
        sample_id,
        sign_in_id
    }})
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const updateResult = async (sign_in_id:number, result:number) => {

    return axios.get(BE_URL + `updateresult`,{params:{
        sign_in_id,
        result
    }})
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const cancelSignIn = async (sign_in_id:number) => {

    return axios.get(BE_URL + `cancelsignin`,{params:{
        sign_in_id
    }})
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const downloadDocuments = async (sign_in_id:number) => {

    return axios.get(BE_URL + `downloaddocuments`,{params:{
        sign_in_id
    }})
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const terminateSignIn = async (sign_in_id:number) => {

    return axios.get(BE_URL + `terminatesignin`,{params:{
        sign_in_id
    }})
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const getTests = async ():Promise<Test[]> => {

    return axios.get(BE_URL + "tests")
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const getSignIns = async () => {

    return axios.get(BE_URL + "signins")
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const getSamples = async () => {

    return axios.get(BE_URL + "samples")
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}

export const getResults = async () => {

    return axios.get(BE_URL + "results")
        .then(res => {
            return res.data
        })
        .catch(error => {throw error})

}
