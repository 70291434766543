import React, { useEffect } from 'react';

import { Test } from './test.model';
import { DownloadButton } from './ResultHandler';
import SignInIdBarcode from './SignInIdBarcode';
import { cancelSignIn } from './BE';

interface TransactionViewerProps {
    test: Test;
    refreshTest: any;
    setFormMessage: React.Dispatch<React.SetStateAction<string>>
}

export const TransactionView = (props: TransactionViewerProps) => {

    const test = props.test;
    const setFormMessage = props.setFormMessage;

    console.log(`TransactionView`);

    const startResultCheckerInterval = () => {
        if (test.result) {
            // console.log('TransactionView - resultCheckerInterval - test has still a result');
            return null
        };
        // console.log('TransactionView - resultCheckerInterval started');
        return setInterval(() => {
            // console.log('TransactionView - resultCheckerInterval is running');
            props.refreshTest(test.sign_in_id);
        }, 10000);
    }

    useEffect(() => {

        // console.log('TransactionView - useEffect');

        const resultCheckerInteval = startResultCheckerInterval();

        return () => {
            // console.log('TransactionView - resultCheckerInterval stopped');
            if (resultCheckerInteval) clearInterval(resultCheckerInteval);
        }

    });

    const onCancelSignIn = (sign_in_id: number) => {
        cancelSignIn(sign_in_id)
            .then(test => {
                // console.log(`TransactionView - onCancelSignIn - test: ${JSON.stringify(test)}`)
                localStorage.removeItem('tmt');
                window.location.reload();
            })
            .catch(error => {
                setFormMessage(`Die Anmeldung kann zur Zeit nicht storniert werden. Versuchen Sie es bitte später noch einmal.`)
                // console.log(`TransactionView - onCancelSignIn - error: ${error.message}`)
            })
    }

    // const onDownloadDocuments = (sign_in_id: number) => {

    // }

    const onTerminateSignIn = (sign_in_id: number) => {
        localStorage.removeItem('tmt');
        window.location.reload();
    }

    return (
        <div>
            <nav className="navbar py-0 navbar-dark bg-primary navbar-expand-lg">
                <div className='navbar-brand title'>
                    Covid Center HH - {test.result ? 'Informieren' : test.sample_id ? 'Testen' : 'Anmelden'}
                </div>
                <button
                    className="navbar-toggler py-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTm1"
                    aria-controls="navbarTm1"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon py-0"></span>
                </button>
                <div className="collapse py-0 navbar-collapse" id="navbarTm1">
                    <ul className="navbar-nav py-0 mr-auto mt-2 mt-lg-0">
                        {test.sample_id ?
                            <li className="nav-item nav-link py-1 disabled">Stornieren</li>
                            :
                            <li
                                className="nav-item nav-link py-1"
                                onClick={() => {
                                    if (window.confirm('Soll die Anmeldung wirklich storniert werden?'))
                                        onCancelSignIn(test.sign_in_id)
                                }}
                            >Stornieren</li>
                        }
                        {/* <li
                            className="nav-item nav-link py-1"
                            onClick={() => {
                                if (window.confirm('Soll der Vorgang wirklich abgeschlossen werden?'))
                                    onTerminateSignIn(test.sign_in_id)
                            }}
                        >Vorgang abschließen</li>
                        <li className="nav-item nav-link py-0 disalbed">Hilfe</li> */}
                    </ul>
                </div>
            </nav>
            {test.result ?
                <div>
                    <div
                        className={test.result === 1 ?
                            'result-ok'
                            : test.result === 2 ?
                                'result-danger'
                                : 'result-warning'}
                    >Testergebnis: {getResultString(test.result)}</div>
                    {test.result === 1 ?
                        <div className='subtitle'>
                            <p>Beachten Sie bitte die Verhaltenshinweise in Ihrer Bescheinigung.</p>
                        </div>
                        : test.result === 2 ?
                            <div className='subtitle'>
                                <p>Beachten Sie bitte die Verhaltenshinweise in Ihrer Bescheinigung.</p>
                            </div>
                            :
                            <div className='subtitle'>
                                <p>Beachten Sie bitte die Verhaltenshinweise in Ihrer Bescheinigung.</p>
                            </div>
                    }
                    <div className="input-wrapper">
                        <input
                            className="form-control"
                            type="string"
                            disabled={true}
                            name="sign_in_id"
                            placeholder="Anmelde-Nr"
                            value={test.sign_in_id}
                        />
                        <label htmlFor="name" className="control-label">Anmelde-Nr</label>
                    </div>
                </div>
                : test.sample_id ?
                    <div>
                        <div className='subtitle'>
                            <p>Ihr Test ist in Arbeit.</p>
                            <div>Sie können das Ergebnis 15 Minuten abrufen
                    <i className="icon-info bi bi-info-circle-fill infobox" >
                                    <div className="infoboxtext">
                                        <p>Der Schnelltest benötigt zur Anzeige des Ergebnisses ca. 15 Minuten.</p>
                                        <p>Anschließend wird das Ergebnis erfasst.</p>
                                        <p>Das Ergebnis erscheint dann auf Ihrem Handy, sobald Sie die Seite neu laden.</p>
                                        <p>Sollten Sie nach 30 Minuten durch das Neuladen der Seite noch immer kein Ergebnis erhalten, wenden Sie sich bitte vertrauensvoll an unsere Hotline unter der Nummer 0152 370 60 831</p>
                                    </div>
                                </i>
                            </div>
                        </div>
                        <div className='barcode-container'>
                            <SignInIdBarcode signInId={test.sign_in_id} />
                        </div>
                    </div>
                    : test.sign_in_id > 1 ?
                        <div>
                            <div className='subtitle'>Zeigen Sie diesen Barcode bitte bei der Probenentnahme.</div>
                            <div className='barcode-container'>
                                <SignInIdBarcode signInId={test.sign_in_id} />
                            </div>
                            <div className="block-message" >Sollten Sie sich versehentlich zu einem neuen Test angemeldet haben, können Sie diese Anmeldung 
                                <a href="#top" className="normal-link" onClick={() => {
                                        if (window.confirm('Soll die Anmeldung wirklich storniert werden?'))
                                            onCancelSignIn(test.sign_in_id)
                                    }}
                                > hier stornieren
                                </a>.</div>
                        </div>
                        : ''
            }
            {test.lastname ?
                <div className="input-wrapper">
                    <input
                        className="form-control"
                        type="string"
                        disabled={true}
                        name="name"
                        placeholder="name"
                        value={test.lastname + (test.prename ? `, ${test.prename}` : '')}
                    />
                    <label htmlFor="name" className="control-label">Name</label>
                </div>
                : ''}
            {!test.sample_time && !test.result_time ?
                <div>
                    {test.phone ?
                        <div className="input-wrapper">
                            <input
                                className="form-control"
                                type="string"
                                disabled={true}
                                name="phone"
                                placeholder="phone"
                                value={test.phone}
                            />
                            <label htmlFor="phone" className="control-label">Telefon-Nr</label>
                        </div>
                        : ''}
                    {test.street ?
                        <div className="input-wrapper">
                            <input
                                className="form-control"
                                type="string"
                                disabled={true}
                                name="address"
                                placeholder="Adresse"
                                value={test.street + ((test.zip || test.city) ? ', ' : '') + (test.zip ? `${test.zip} ` : '') + (test.city ? test.city : '')}
                            />
                            <label htmlFor="address" className="control-label">Adresse</label>
                        </div>
                        : ''}

                </div>
                : ''}
            {/* {test.email ?
        <div className="input-wrapper">
            <input
                className="form-control"
                type="string"
                disabled={true}
                name="email"
                placeholder="eMail"
                value={test.email}
            />
            <label htmlFor="email" className="control-label">eMail</label>
        </div>
        : ''} */}
            {test.result_time ?
                <div className="input-wrapper">
                    <input
                        className="form-control"
                        type="string"
                        disabled={true}
                        name="result_time"
                        placeholder="Ergebnis ermittelt"
                        // value={getTimeString(test.result_time)}
                        value={new Date(test.result_time).toLocaleString([], {day: '2-digit',month: '2-digit',year: 'numeric', hour: '2-digit', minute:'2-digit'})}
                    />
                    <label htmlFor="result_time" className="control-label">Ergebnis ermittelt</label>
                </div>
                : ''}
            {test.sample_time ?
                <div className="input-wrapper">
                    <input
                        className="form-control"
                        type="string"
                        disabled={true}
                        name="sample_time"
                        placeholder="Probe entnommen"
                        // value={getTimeString(test.sample_time)}
                        value={new Date(test.sample_time).toLocaleString([], {day: '2-digit',month: '2-digit',year: 'numeric', hour: '2-digit', minute:'2-digit'})}
                    />
                    <label htmlFor="sample_time" className="control-label">Probe entnommen</label>
                </div>
                : ''}
            <div className='button-bar'>
                {test.result_time && !test.download_time ?
                    <div>
                        <DownloadButton test={test} />
                    </div>
                    : ''}
                {test.download_time ?
                    <div>
                        <button type="button" onClick={() => onTerminateSignIn(test.sign_in_id)} className="btn btn-primary button-solitaire">
                            Vorgang beenden
                </button>
                    </div>
                    : ''}
            </div>
        </div>
    )

}

const getResultString = (result: number) => {
    return result === 1 ? 'negativ' : result === 2 ? 'positiv' : 'Test defekt';
}

const getTimeString = (time: number) => {

    const diffInHours = Math.trunc((Date.now() - time) / 1000 / 60 / 60);

    let timeString = diffInHours < 24 ? `heute um ${new Date(time).getHours()}:${new Date(time).getMinutes()} Uhr` : 'vor mehr als einem Tag'
    timeString += ` (${getElapsedPeriodString(Math.trunc(Date.now() - time) / 1000)})`;
    return timeString;

}

const getElapsedPeriodString = (sec: number) => {
    const h = Math.floor(sec / 3600);
    const m = Math.floor(sec % 3600 / 60);
    const s = Math.floor(sec % 3600 % 60);

    if (h > 0) { return `vor mehr als ${h} Std. `; };
    if (m > 0) { return `vor ${m} Min. `; };
    if (s > 0) { return `soeben `; };

    const hString = h > 0 ? h + (h === 1 ? " Std., " : " Std., ") : "";
    const mString = m > 0 ? m + (m === 1 ? " Min., " : " Min., ") : "";
    const sString = s > 0 ? s + (s === 1 ? " Sek." : " Sek.") : "";

    return 'vor ' + hString + mString + sString;

}
