import React from 'react';

import { PDFDownloadLink, Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Test } from './test.model';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column'
  },
  header: {
    margin: 10,
    padding: 10
  },
  title: {
    fontSize: 36,
    color: 'blue',
    textAlign: 'center'
  },
  subtitle1: {
    fontSize: 26,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  subtitle2: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  result: {
    fontSize: 26,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  section: {
    margin: 10,
    paddingLeft: 70,
    paddingRight: 30
  },
  signature: {
    marginLeft: 10,
    paddingLeft: 70,
    paddingRight: 30
  },
  signatureexplanation: {
    fontSize: 12,
    marginLeft: 10,
    paddingLeft: 70,
    paddingRight: 80
  },
  footer: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center'
  },
});

interface DownloadLinkProps {
  test: Test
}

export const DownloadLink = (props: DownloadLinkProps) => {

  const test = props.test

  const ResultDocument = () => (

    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Test-Zentrum Pinneberg</Text>
        </View>
        <View style={styles.section} />
        <View style={styles.section}>
          <Text style={styles.subtitle1}>Z E R T I F I K A T</Text>
          <View style={styles.section} />
          <Text style={styles.subtitle2}>über die Durchführung</Text>
          <Text style={styles.subtitle2}>eines SARS-COV2-Antigen-Tests</Text>
        </View>
        <View style={styles.section} />
        <View style={styles.section}>
          <Text>Am {new Date(test.sign_in_id).toLocaleString([], {day: '2-digit',month: '2-digit',year: 'numeric', hour: '2-digit', minute:'2-digit'})} Uhr wurde für {test.prename} {test.lastname}</Text>
          <Text>im Testzentrum-Pinneberg, Westring 6, 25421 Pinneberg</Text>
          <Text>ein SARS-COV2-Antigen-Tests durchgeführt.</Text>
        </View>
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.section}>
          <Text style={styles.result}>
            {test.result === 1 ?
              'Der Test hat einen negativen Befund.'
              : test.result === 2 ?
                'Der Test hat einen positiven Befund.'
                :
                'Der Test hat kein eindeutiges Result ergeben.'
            }
          </Text>
        </View>
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.section}>
          <Text>Beachten Sie bitte die Verhaltenshinweise auf der nächsten Seite.</Text>
        </View>
        <View style={styles.section}>
          <Text>Für den Test verantwortlich ist Karl-Ernst Hartkopf vom Testzentrum-Pinneberg, Telefon: 0152 370 60 831.</Text>
        </View>
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.section}>
          <Text>Pinneberg, den {new Date(test.sign_in_id).toLocaleString([], {day: '2-digit',month: '2-digit',year: 'numeric'})}</Text>
        </View>
        <View style={styles.section} />
        <View style={styles.signature}>
          <Text>gez. Karl-Ernst Hartkopf</Text>
        </View>
        <View style={styles.signatureexplanation}>
          <Text>Dieses Dokument ist maschinell erstellt und auch ohne Unterschrift gültig.</Text>
        </View>
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.footer}>
          <Text>Testzentrum-Pinneberg - Westring 6 - 25421 Pinneberg - Telefon: 0152 370 60 831</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Test-Zentrum Pinneberg</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subtitle1}>Verhaltenshinweise</Text>
        </View>
        <View style={styles.section}>
          <Text>
            Ein Testergebniss stellt Momentaufnahme dar. Er senkt jedoch das Risiko, andere ohne Corona-Symptome unwissentlich anzustecken.
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            Die Einhaltung Hygiene- und Schutzmaßnahmen (Stichwort AHA+L-Formel) schützt uns alle.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subtitle1}>Datenschutzinformation</Text>
        </View>
        <View style={styles.section}>
          <Text>
            Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten ist: Testzentrum-Pinneberg, Inhaber: Karl Ernst Hartkopf. Wir verwenden die von Ihnen mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit b bzw. (in Bezug auf Gesundheitsdaten) Art. 9 Abs. 2 lit. b DSGVO zur Vertragsabwicklung. Weitere Informationen zur Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten, Sperrung oder Löschung von Daten erhalten Sie von uns unter der oben angegebenen Kontaktadresse.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subtitle1}>Rechtlicher Hinweis</Text>
        </View>
        <View style={styles.section}>
          <Text>
            Wer dieses Dokument fälscht oder einen nicht erfolgten Test unrichtig bescheinigt oder einen positiven Test vorsätzlich als negativ bescheinigt, macht sich nach § 267 StGB der Urkundenfälschung strafbar. Wer ein gefälschtes Dokument verwendet, um Zugang zu einer Einrichtung oder einem Angebot zu erhalten, begeht eine Ordnungswidrigkeit, die mit einer Geldbuße geahndet wird. Jeder festgestellte Verstoß wird zur Anzeige gebracht.</Text>
        </View>
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.section} />
        <View style={styles.footer}>
          <Text>Testzentrum-Pinneberg - Westring 6 - 25421 Pinneberg - Telefon: 0152 370 60 831</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink document={<ResultDocument />} fileName="Schnelltest-Bescheinigung.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Bescheinigung speichern'
      }
    </PDFDownloadLink>
  );
}

export const DownloadButton = (props: DownloadLinkProps) => {

  const test = props.test;

  return (
    <button className="btn btn-primary button-solitaire">
      <DownloadLink test={test} />
    </button>
  )
}
