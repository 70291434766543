import React from 'react';
import { useBarcode } from 'react-barcodes';

type SignInIdProps = {
  signInId:number
}

const SignInIdBarcode = (props:SignInIdProps) => {
  const signInIdString = props.signInId ? props.signInId.toString() : '0';
  const { inputRef } = useBarcode({value: signInIdString});
  
  return <svg ref={inputRef} />;
};

export default SignInIdBarcode;